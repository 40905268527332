import React from "react"

import Header from "../components/header"
import Footer from "../components/footer"

import SEO from "../components/seo"

import { StaticImage } from "gatsby-plugin-image"

import "./index.css"

export default function LeinwandgemaeldePage() {
  return (
    <>
      <SEO
        description="restaura ratisbona, Ihr Team für Restaurierungen. Leinwandgemaelde"
        title="restaura ratisbona, Ihr Team für Restaurierungen. Leinwandgemaelde"
        lang="de"
      />
      <Header />
      <section>
        <h1 className="indexHeadline">Leinwand&shy;gemaelde</h1>
        <h2 className="referenceH2">
          Evang.- Luth. Pfarrkirche St. Peter und Paul, Ammerndorf (Fürth)
        </h2>
        <h3 className="referenceH3">
          Restaurierung von großformatigen Leinwandgemälden an der Westwand, die
          zwei unterschiedliche Künstler im nazarenischen Stil schufen.
          Ausführung von Schadensbehebungen, Konservierungsarbeiten,
          Reinigungsarbeiten, Retusche und Rückseitenschutz.
        </h3>
        <div className="referencePageDiv">
          <div className="referenceImages">
            <label htmlFor="Leinwandgemaelde1">
              <StaticImage
                src="../images/Leinwandgemaelde1.jpg"
                id="Leinwandgemaelde1"
              />
              Vorzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Leinwandgemaelde2">
              <StaticImage
                src="../images/Leinwandgemaelde2.jpg"
                id="Leinwandgemaelde2"
              />
              Vorzustand, Detail Frühschwundrisse
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Leinwandgemaelde8">
              <StaticImage
                src="../images/Leinwandgemaelde3.jpg"
                id="Leinwandgemaelde3"
              />
              Vorzustand, Detail Frühschwundrisse
              <br />
              (Foto: Astrid Mendes)
            </label>
            {/* <label htmlFor="Leinwandgemaelde4">
            <StaticImage src="../images/Leinwandgemaelde4.jpg" id="Leinwandgemaelde4" />
              Befund: Signatur Müller Karl, 1848
              <br />
              (Foto: Astrid Mendes)
            </label> */}
            <label htmlFor="Leinwandgemaelde5">
              <StaticImage
                src="../images/Leinwandgemaelde5.jpg"
                id="Leinwandgemaelde5"
              />
              Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Leinwandgemaelde6">
              <StaticImage
                src="../images/Leinwandgemaelde6.jpg"
                id="Leinwandgemaelde6"
              />
              Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
            <label htmlFor="Leinwandgemaelde7">
              <StaticImage
                src="../images/Leinwandgemaelde7.jpg"
                id="Leinwandgemaelde7"
              />
              Endzustand
              <br />
              (Foto: Astrid Mendes)
            </label>
          </div>
          <div className="referenceTextDiv">
            <h4 className="referenceH4">Geschichte und Bedeutung</h4>
            <p className="referenceText">
              Das Leinwandgemälde „Anbetung der Könige“, befindet sich im
              südwestlichen Bereich auf der dreiseitigen Empore der Saalkirche.
              Der Malstil entstand unter dem Einfluss der nazarenischen Kunst,
              die auf einer romantisch-religiösen Sichtweise fußt. Der Fürther
              Farbrikbesitzer Spiegelberger erwarb dieses Gemälde 1922 von der
              Eisenbahndirektion Nürnberg für die Ammerndorfer Kirchengemeinde
              (Verweis Robert Lehy, Kirchenführer, Kroner 1989, S. 266.)
            </p>

            <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
            <p className="referenceText">
              Das Gemälde ist in Öltechnik auf eine Leinwand in „Küperbindung“.
              Entsprechend der verwendeten Materialien in dieser Zeit weisen die
              dunklen Partien Frühschwundrisse auf. Das Bild kann aufgrund der
              Signatur auf 1848 datiert werden und ist dem Künstler „MK“
              zuzuschreiben. Das Namenskürzel „MK“ lässt auf den Künstler Karl
              Müller (1818 – 1893) schließen Er zählt zu den Spätnazarenern und
              soll in der Düsseldorfer Kunstakademie unter der Leitung von
              Friedrich Wilhelm von Schadow (1788 – 1862) seine Ausbildung
              erhalten haben. Das Gemälde besitzt die Maße 231 x 172 cm.
            </p>

            <h4 className="referenceH4">Restauratorische Zielstellung</h4>
            <p className="referenceText">
              Geplant ist eine Malschichtsicherung, eine Oberflächenreinigung
              sowie eine Ausreinigung der rückseitigen Schmutztaschen und die
              Anbringung eines Rückseitenschutzes. Es soll ein gepflegter
              Zustand erzielt werden.
            </p>

            <h4 className="referenceH4">Maßnahmen</h4>
            <p className="referenceText">
              Reinigung der stark verschmutzen Bildvorderseite. Ausräumen der
              Schmutztaschen Behebung von kleinen Deformierungen. Retusche an
              den gravierendsten Frühschwundrisse beispielsweise am Haarhaupt
              des Jünglings und an der Krone des Königs. Retusche einer
              ehemaligen Reinigungsprobe.
            </p>

            <h4 className="referenceH4">Auftraggeber</h4>
            <p className="referenceText">
              Staatliches Hochbauamt Nürnberg und Evang.-Luth. Kirche.
            </p>

            <h4 className="referenceH4">Fachbehörden</h4>
            <p className="referenceText" style={{ paddingBottom: "0.5rem" }}>
              Staatliches Hochbauamt Frau Elisabeth Schildt, Nürnberg
            </p>
            <p className="referenceText" style={{ paddingBottom: "2rem" }}>
              Architekturbüro Gerhard Meier, Neumarkt
            </p>

            <h4 className="referenceH4">Für die Forschung</h4>
            <ul className="listRefs"> 
              <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
                Hermann Alexander Müller: Biographisches Künstler-Lexikon.
                Verlag des Bibliographischen Instituts, Leipzig 1882, S. 384
                (Digitalisat)
              </li>
              <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
                Heinrich Finke: Carl Müller. Sein Leben und künstlerisches
                Schaffen (= Görres-Gesellschaft zur Pflege der Wissenschaft im
                Katholischen Deutschland, Vereinsschrift). Bachem, Köln 1896
                (archive.org)
              </li>
              <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
                Eduard Daelen: Müller, Karl. In: Allgemeine Deutsche Biographie
                (ADB). Band 52, Duncker & Humblot, Leipzig 1906, S. 519–521{" "}
              </li>
              <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
                Hans Vollmer: Müller, Karl. In: Hans Vollmer (Hrsg.):
                Allgemeines Lexikon der Bildenden Künstler von der Antike bis
                zur Gegenwart. Begründet von Ulrich Thieme und Felix Becker.
                Band 25: Moehring–Olivié. E. A. Seemann, Leipzig 1931, S. 240{" "}
              </li>
              <li className="referenceText">
                Dieter Graf: Die Düsseldorfer Spätnazarener in Remagen und
                Stolzenfels. In: Wend von Kalnein (Hrsg.): Die Düsseldorfer
                Malerschule. Verlag Philipp von Zabern, Main 1979, ISBN
                3-8053-0409-9, S. 121 ff
              </li>
            </ul>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

/* 
<h1 className="indexHeadline">Leinwand&shy;gemaelde</h1>
<h2 className="referenceH2">
  Evang.- Luth. Pfarrkirche St. Peter und Paul, Ammerndorf (Fürth)
</h2>
<h3 className="referenceH3">
  Restaurierung von großformatigen Leinwandgemälden an der Westwand, die
  zwei unterschiedliche Künstler im nazarenischen Stil schufen.
  Ausführung von Schadensbehebungen, Konservierungsarbeiten,
  Reinigungsarbeiten, Retusche und Rückseitenschutz.
</h3>
<div className="referencePageDiv">
  <div className="referenceImages">
    <label htmlFor="Leinwandgemaelde1">
      <StaticImage
        src="../images/Leinwandgemaelde1.jpg"
        id="Leinwandgemaelde1"
      />
      Vorzustand
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde2">
      <StaticImage
        src="../images/Leinwandgemaelde2.jpg"
        id="Leinwandgemaelde2"
      />
      Vorzustand, Detail Frühschwundrisse
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde8">
      <StaticImage
        src="../images/Leinwandgemaelde3.jpg"
        id="Leinwandgemaelde3"
      />
      Vorzustand, Detail Frühschwundrisse
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde4">
    <StaticImage src="../images/Leinwandgemaelde4.jpg" id="Leinwandgemaelde4" />
      Befund: Signatur Müller Karl, 1848
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde5">
      <StaticImage
        src="../images/Leinwandgemaelde5.jpg"
        id="Leinwandgemaelde5"
      />
      Endzustand
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde6">
      <StaticImage
        src="../images/Leinwandgemaelde6.jpg"
        id="Leinwandgemaelde6"
      />
      Endzustand
      <br />
      (Foto: Astrid Mendes)
    </label>
    <label htmlFor="Leinwandgemaelde7">
      <StaticImage
        src="../images/Leinwandgemaelde7.jpg"
        id="Leinwandgemaelde7"
      />
      Endzustand
      <br />
      (Foto: Astrid Mendes)
    </label>
  </div>
  <div className="referenceTextDiv">
    <h4 className="referenceH4">Geschichte und Bedeutung</h4>
    <p className="referenceText">
      Das Leinwandgemälde „Anbetung der Könige“, befindet sich im
      südwestlichen Bereich auf der dreiseitigen Empore der Saalkirche.
      Der Malstil entstand unter dem Einfluss der nazarenischen Kunst,
      die auf einer romantisch-religiösen Sichtweise fußt. Der Fürther
      Farbrikbesitzer Spiegelberger erwarb dieses Gemälde 1922 von der
      Eisenbahndirektion Nürnberg für die Ammerndorfer Kirchengemeinde
      (Verweis Robert Lehy, Kirchenführer, Kroner 1989, S. 266.)
    </p>

    <h4 className="referenceH4">Erhaltungszustand / Befundsituation</h4>
    <p className="referenceText">
      Das Gemälde ist in Öltechnik auf eine Leinwand in „Küperbindung“.
      Entsprechend der verwendeten Materialien in dieser Zeit weisen die
      dunklen Partien Frühschwundrisse auf. Das Bild kann aufgrund der
      Signatur auf 1848 datiert werden und ist dem Künstler „MK“
      zuzuschreiben. Das Namenskürzel „MK“ lässt auf den Künstler Karl
      Müller (1818 – 1893) schließen Er zählt zu den Spätnazarenern und
      soll in der Düsseldorfer Kunstakademie unter der Leitung von
      Friedrich Wilhelm von Schadow (1788 – 1862) seine Ausbildung
      erhalten haben. Das Gemälde besitzt die Maße 231 x 172 cm.
    </p>

    <h4 className="referenceH4">Restauratorische Zielstellung</h4>
    <p className="referenceText">
      Geplant ist eine Malschichtsicherung, eine Oberflächenreinigung
      sowie eine Ausreinigung der rückseitigen Schmutztaschen und die
      Anbringung eines Rückseitenschutzes. Es soll ein gepflegter
      Zustand erzielt werden.
    </p>

    <h4 className="referenceH4">Maßnahmen</h4>
    <p className="referenceText">
      Reinigung der stark verschmutzen Bildvorderseite. Ausräumen der
      Schmutztaschen Behebung von kleinen Deformierungen. Retusche an
      den gravierendsten Frühschwundrisse beispielsweise am Haarhaupt
      des Jünglings und an der Krone des Königs. Retusche einer
      ehemaligen Reinigungsprobe.
    </p>

    <h4 className="referenceH4">Auftraggeber</h4>
    <p className="referenceText">
      Staatliches Hochbauamt Nürnberg und Evang.-Luth. Kirche.
    </p>

    <h4 className="referenceH4">Fachbehörden</h4>
    <p className="referenceText" style={{ paddingBottom: "0.5rem" }}>
      Staatliches Hochbauamt Frau Elisabeth Schildt, Nürnberg
    </p>
    <p className="referenceText" style={{ paddingBottom: "2rem" }}>
      Architekturbüro Gerhard Meier, Neumarkt
    </p>

    <h4 className="referenceH4">Für die Forschung</h4>
    <ul className="listRefs"> 
      <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
        Hermann Alexander Müller: Biographisches Künstler-Lexikon.
        Verlag des Bibliographischen Instituts, Leipzig 1882, S. 384
        (Digitalisat)
      </li>
      <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
        Heinrich Finke: Carl Müller. Sein Leben und künstlerisches
        Schaffen (= Görres-Gesellschaft zur Pflege der Wissenschaft im
        Katholischen Deutschland, Vereinsschrift). Bachem, Köln 1896
        (archive.org)
      </li>
      <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
        Eduard Daelen: Müller, Karl. In: Allgemeine Deutsche Biographie
        (ADB). Band 52, Duncker & Humblot, Leipzig 1906, S. 519–521{" "}
      </li>
      <li className="referenceText" style={{ paddingBottom: "0.5rem" }}>
        Hans Vollmer: Müller, Karl. In: Hans Vollmer (Hrsg.):
        Allgemeines Lexikon der Bildenden Künstler von der Antike bis
        zur Gegenwart. Begründet von Ulrich Thieme und Felix Becker.
        Band 25: Moehring–Olivié. E. A. Seemann, Leipzig 1931, S. 240{" "}
      </li>
      <li className="referenceText">
        Dieter Graf: Die Düsseldorfer Spätnazarener in Remagen und
        Stolzenfels. In: Wend von Kalnein (Hrsg.): Die Düsseldorfer
        Malerschule. Verlag Philipp von Zabern, Main 1979, ISBN
        3-8053-0409-9, S. 121 ff
      </li>
    </ul>
  </div>
</div> */